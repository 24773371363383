.loadingText {
  position: absolute;
  top: 56%;
  left: 50%;
  transform: translate(-50%, -56%);
}
.ring {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45px;
  height: 45px;
  background: transparent;
  border: 3px solid #3c3c3c;
  border-radius: 50%;
  text-align: center;
  line-height: 150px;
  font-family: sans-serif;
  font-size: 20px;
  color: #ffff39;
  letter-spacing: 4px;
  text-transform: uppercase;
  text-shadow: 0 0 10px #ffff39;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}
.ring:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 103%;
  height: 104%;
  border: 3px solid transparent;
  border-top: 3px solid #ffff39;
  border-right: 3px solid #ffff39;
  border-radius: 50%;
  animation: animateC 2s linear infinite;
}
.ring span {
  display: block;
  position: absolute;
  top: calc(50% - 2px);
  left: 50%;
  width: 50%;
  height: 4px;
  background: transparent;
  transform-origin: left;
  animation: animate 2s linear infinite;
}
/* .ring span:before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ffff39;
  top: -6px;
  right: -4px;
  box-shadow: 0 0 20px #ffff39;
} */
@keyframes animateC {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(405deg);
  }
}
