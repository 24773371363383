html,
body {
  max-width: 100%;
  height: 100%;
  overflow-x: hidden;
  scroll-margin-top: 180px;
}

#root {
  height: 100%;
}

@font-face {
  font-family: 'MontserratRegular';
  src: local('MontserratRegular'),
    url('./fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'MontserratMedium';
  src: local('MontserratMedium'),
    url('./fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'MontserratBold';
  src: local('MontserratBold'),
    url('./fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'MontserratBold';
  src: local('MontserratBold'),
    url('./fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'TriakisFont-SemiBold';
  src: local('TriakisFont-SemiBold'),
    url('./fonts/TriakisFont-SemiBold.otf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'TriakisFont-Heavy';
  src: local('TriakisFont-Heavy'),
    url('./fonts/TriakisFont-Heavy.otf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'MontserratItal';
  src: local('MontserratItal'),
    url('./fonts/Montserrat-LightItalic.ttf') format('truetype');
  font-weight: lighter;
}

tr {
  vertical-align: top !important;
}

td:last-child {
  padding-left: 8px;
}

.rt-td {
  white-space: normal !important;
}

.react-tel-input .form-control {
  width: 100% !important;
  color: #9b9b9b !important;
  border: none !important;
  background: transparent !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #666666 !important;
}

.react-tel-input .form-control {
  color: #70fafd !important;
  font-size: 0.8rem !important;
  font-weight: 300 !important;
}

.react-tel-input .form-control:focus {
  color: #70fafd !important;
  border-bottom: 1px solid #70fafd !important;
}

.react-tel-input .flag-dropdown {
  background: transparent !important;
  border: none !important;
  border-radius: 0 !important;
  /* border-bottom: 1px solid #70fafd !important; */
}

.react-tel-input .selected-flag .arrow {
  border-left: 3px solid transparent !important;
  border-right: 3px solid transparent !important;
  border-top: 4px solid #ffffff !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent !important;
}
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: transparent !important;
}

.hide-desktop {
  display: none;
}

@media only screen and (max-width: 600px) {
  .hide-desktop {
    display: block;
  }
}

td div {
  color: #70fafd !important;
}

.MuiBottomNavigationAction-root {
  min-width: 30px !important;
  align-self: baseline !important;
}
.MuiBottomNavigationAction-label.Mui-selected {
  font-size: 10px !important;
}
.MuiBottomNavigationAction-label {
  font-size: 10px !important;
  font-family: 'MontserratRegular' !important;
}
.MuiButtonBase-root.MuiBottomNavigationAction-root {
  padding: 0 !important;
  margin: 5px !important;
}
.MuiBottomNavigation-root {
  padding-right: 10px !important;
}

.eventIcon > svg {
  display: none;
}

.base-button {
  padding: 0 !important;
  width: auto !important;
  border: none !important;
  margin-bottom: 0 !important;
  margin-top: -5px !important;
}
.pursuitIndexButtons {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.pursuitIndexButtons button {
  width: 180px;
  border: 10px solid linear-gradient(to left, #70fafd 60%, #ffe139);
  border-image-slice: 1;
  border-width: 1.5px;
  background: none;
  font-weight: 300;
  font-size: 0.8rem;
  padding: 0.3rem 1rem;
  text-transform: uppercase;
  color: #ffe139;
  border-image-source: linear-gradient(to left, #70fafd 60%, #ffe139);
  margin-bottom: 1rem;
}

.pursuitIndexButtons button.active {
  border: 10px solid linear-gradient(to left, #70fafd 60%, #ffe139);
  border-image-slice: 1;
  border-width: 1px;
  background: none;
  font-size: 0.8rem;
  padding: 0.3rem 1rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #000;
  border-image-source: linear-gradient(to left, #70fafd 60%, #ffe139);
  margin-bottom: 1rem;
  background: linear-gradient(to left, #70fafd 60%, #ffe139);
}

.active-discipline-events {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
  margin-bottom: 20px;
  margin-top: -15px;
  margin-left: auto;
  margin-right: auto;
}

label.MuiInputLabel-root.MuiInputLabel-formControl {
  color: #ffffff;
}

.MuiInput-root.MuiInput-underline input {
  font-weight: 0.8rem !important;
}

input.MuiInput-input.MuiInputBase-input.MuiInputBase-input-MuiInput-input {
  font-weight: 0.8rem !important;
}
